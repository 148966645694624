.p-dialog .p-confirm-dialog-accept {
    background: #ed1e7a;
    color: white;
  
}

button.p-confirm-dialog-reject:hover {
    color: black !important;
    background-color: #ed1e7a !important;
}

button.p-confirm-dialog-accept:hover{
    border-color: #ed1e7a !important;
    background-color: white !important;
    color: black !important;
    transition: #ed1e7a;

}

.p-dialog .p-confirm-dialog-reject {
    border: none;
    color: black;
}



#button-group {
    width: 120px;
    position: relative;
   
}

/* .p-toast .p-toast-message {
    margin-top: 10em !important;
} */